import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createBlock(_component_FormField, {
    showLabel: false,
    containerClass: _ctx.containerClass,
    label: _ctx.label,
    options: _ctx.options,
    required: false,
    placeholder: _ctx.selectPlaceholder,
    onSelectChange: _ctx.handleChange,
    onSelectCloseOrDeselect: _ctx.handleClose,
    type: "multiselect"
  }, null, 8, ["containerClass", "label", "options", "placeholder", "onSelectChange", "onSelectCloseOrDeselect"]))
}