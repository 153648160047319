
import { Reminder } from '@/models/ReminderModel';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'reminder-lists-tabs',
  emits: ['newReminder', 'selectReminder'],
  props: {
    reminders: {
      type: Object,
      default: () => {
        return {} as Reminder;
      },
    },
    currentReminder: {
      type: Object,
      default: () => {
        return {} as Reminder;
      },
    },
    listLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: true,
    currentReminderDays: '',
  }),
  mounted() {
    this.loading = false;
  },
  methods: {
    addReminder() {
      this.loading = false;
      this.$emit('newReminder');
    },
    selectReminder(reminder) {
      this.loading = false;
      this.currentReminderDays = reminder.no_of_days;
      this.$emit('selectReminder', reminder);
    },
    isActiveReminder(reminder) {
      return this.currentReminderDays == reminder.no_of_days;
    },
  },
});
