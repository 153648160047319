export default [
  'code',
  'undo',
  'redo',
  'aligncenter',
  'alignjustify',
  'alignleft',
  'alignnone',
  'alignright',
  'blockquote',
  'backcolor',
  'blocks',
  'bold',
  'copy',
  'cut',
  'fontfamily',
  'fontsize',
  'forecolor',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hr',
  'indent',
  'italic',
  'language',
  'lineheight',
  'newdocument',
  'outdent',
  'paste',
  'pastetext',
  'print',
  'remove',
  'removeformat',
  'selectall',
  'strikethrough',
  'styles',
  'subscript',
  'superscript',
  'underline',
  'visualaid',
] as Array<string>;
