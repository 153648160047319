import { createValidationObjectSchema } from '@/utils/validations';

export const Reminders = createValidationObjectSchema({
  send_when: {
    required: true,
  },
  no_of_days: {
    required: true,
  },
});
