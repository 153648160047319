import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column w-100" }
const _hoisted_2 = { class: "d-flex pb-5 flex-row justify-content-between" }
const _hoisted_3 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReminderTabs = _resolveComponent("ReminderTabs")!
  const _component_EmailReminderEditorForm = _resolveComponent("EmailReminderEditorForm")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.currentSystemName)
        ? (_openBlock(), _createBlock(_component_ReminderTabs, {
            key: 0,
            reminders: _ctx.reminders,
            currentReminder: _ctx.currentReminder,
            listLoading: _ctx.loading,
            onNewReminder: _ctx.handleNewReminder,
            onSelectReminder: _ctx.handleSelectReminder
          }, null, 8, ["reminders", "currentReminder", "listLoading", "onNewReminder", "onSelectReminder"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.showFormLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.showForm)
            ? (_openBlock(), _createBlock(_component_EmailReminderEditorForm, {
                key: 0,
                onCreateReminder: _ctx.handeCreateReminder,
                onUpdateReminder: _ctx.handleUpdateReminder,
                onDeleteReminder: _ctx.handleDeleteReminder,
                pageLoading: _ctx.pageLoading,
                formSubmitted: _ctx.formSubmitted,
                actionLoading: _ctx.actionLoading,
                currentReminder: _ctx.currentReminder,
                placeholders: _ctx.placeholders,
                title: _ctx.title,
                placeholderOptions: _ctx.placeholderOptions
              }, null, 8, ["onCreateReminder", "onUpdateReminder", "onDeleteReminder", "pageLoading", "formSubmitted", "actionLoading", "currentReminder", "placeholders", "title", "placeholderOptions"]))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createBlock(_component_el_skeleton, {
          key: 1,
          rows: 6
        }))
  ]))
}